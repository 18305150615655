.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.\!left-0 {
  left: 0px !important;
}

.\!top-16 {
  top: 4rem !important;
}

.\!top-\[300\%\] {
  top: 300% !important;
}

.\!top-\[40px\] {
  top: 40px !important;
}

.-bottom-\[2px\] {
  bottom: -2px;
}

.-left-3 {
  left: -0.75rem;
}

.-left-4 {
  left: -1rem;
}

.-right-3 {
  right: -0.75rem;
}

.-right-4 {
  right: -1rem;
}

.-right-\[5px\] {
  right: -5px;
}

.bottom-1 {
  bottom: 0.25rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[-8px\] {
  bottom: -8px;
}

.bottom-\[44px\] {
  bottom: 44px;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.left-0 {
  left: 0px;
}

.left-1 {
  left: 0.25rem;
}

.left-6 {
  left: 1.5rem;
}

.right-0 {
  right: 0px;
}

.right-1 {
  right: 0.25rem;
}

.right-4 {
  right: 1rem;
}

.right-6 {
  right: 1.5rem;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 0.25rem;
}

.top-10 {
  top: 2.5rem;
}

.top-2 {
  top: 0.5rem;
}

.top-2\.5 {
  top: 0.625rem;
}

.top-4 {
  top: 1rem;
}

.top-6 {
  top: 1.5rem;
}

.top-8 {
  top: 2rem;
}

.top-\[44px\] {
  top: 44px;
}

.top-\[52px\] {
  top: 52px;
}

.top-\[60px\] {
  top: 60px;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[60\] {
  z-index: 60;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-0 {
  margin-left: 0px;
}

.ml-0\.5 {
  margin-left: 0.125rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-2\.5 {
  margin-left: 0.625rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-\[6px\] {
  margin-left: 6px;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.\!h-0 {
  height: 0px !important;
}

.\!h-\[42px\] {
  height: 42px !important;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0px;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: 0.5rem;
}

.h-20 {
  height: 5rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[0\] {
  height: 0;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[216px\] {
  height: 216px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[33px\] {
  height: 33px;
}

.h-\[440px\] {
  height: 440px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[600px\] {
  height: 600px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[65px\] {
  height: 65px;
}

.h-\[76px\] {
  height: 76px;
}

.h-\[auto\] {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[700px\] {
  max-height: 700px;
}

.max-h-\[75vh\] {
  max-height: 75vh;
}

.max-h-\[80vh\] {
  max-height: 80vh;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.min-h-\[12rem\] {
  min-height: 12rem;
}

.min-h-\[500px\] {
  min-height: 500px;
}

.min-h-\[50px\] {
  min-height: 50px;
}

.min-h-\[600px\] {
  min-height: 600px;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-0 {
  width: 0px !important;
}

.\!w-\[120px\] {
  width: 120px !important;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-10 {
  width: 2.5rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: 0.5rem;
}

.w-20 {
  width: 5rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[33px\] {
  width: 33px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[56px\] {
  width: 56px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[90vw\] {
  width: 90vw;
}

.w-\[full\] {
  width: full;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-\[40px\] {
  min-width: 40px;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-\[360px\] {
  max-width: 360px;
}

.max-w-\[384px\] {
  max-width: 384px;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.max-w-\[70\%\] {
  max-width: 70%;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.origin-top-right {
  transform-origin: top right;
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[135deg\] {
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[225deg\] {
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes hue {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }

  100% {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.animate-hue {
  animation: hue 10s infinite linear;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.\!cursor-help {
  cursor: help !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.cursor-wait {
  cursor: wait;
}

.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.resize {
  resize: both;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[8px\] {
  gap: 8px;
}

.gap-x-1 {
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

.gap-x-1\.5 {
  -moz-column-gap: 0.375rem;
       column-gap: 0.375rem;
}

.gap-x-10 {
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem;
}

.gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.gap-x-5 {
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[1px\] {
  border-radius: 1px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-l-\[1px\] {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.rounded-r-\[1px\] {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.border {
  border-width: 1px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.\!border-\[\#EF2929\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 41 41 / var(--tw-border-opacity)) !important;
}

.border-\[\#667085\] {
  --tw-border-opacity: 1;
  border-color: rgb(102 112 133 / var(--tw-border-opacity));
}

.border-\[\#AAA\] {
  --tw-border-opacity: 1;
  border-color: rgb(170 170 170 / var(--tw-border-opacity));
}

.border-\[\#EF2929\] {
  --tw-border-opacity: 1;
  border-color: rgb(239 41 41 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-utility-error-200 {
  --tw-border-opacity: 1;
  border-color: rgb(145 32 24 / var(--tw-border-opacity));
}

.border-utility-warning-300 {
  --tw-border-opacity: 1;
  border-color: rgb(181 71 8 / var(--tw-border-opacity));
}

.border-v2-lily\/10 {
  border-color: rgb(232 249 255 / 0.1);
}

.border-v2-lily\/5 {
  border-color: rgb(232 249 255 / 0.05);
}

.border-v2-primary\/5 {
  border-color: rgba(199, 242, 132, 0.05);
}

.border-v3-primary\/50 {
  border-color: rgb(199 242 132 / 0.5);
}

.border-warning\/50 {
  border-color: rgb(250 166 60 / 0.5);
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-white\/10 {
  border-color: rgb(255 255 255 / 0.1);
}

.border-white\/20 {
  border-color: rgb(255 255 255 / 0.2);
}

.border-white\/50 {
  border-color: rgb(255 255 255 / 0.5);
}

.border-opacity-10 {
  --tw-border-opacity: 0.1;
}

.\!bg-black\/20 {
  background-color: rgb(0 0 0 / 0.2) !important;
}

.\!bg-transparent {
  background-color: transparent !important;
}

.bg-\[\#010101\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 1 1 / var(--tw-bg-opacity));
}

.bg-\[\#111111\] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity));
}

.bg-\[\#191B1F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(25 27 31 / var(--tw-bg-opacity));
}

.bg-\[\#1B1B1E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 27 30 / var(--tw-bg-opacity));
}

.bg-\[\#212128\] {
  --tw-bg-opacity: 1;
  background-color: rgb(33 33 40 / var(--tw-bg-opacity));
}

.bg-\[\#23C1AA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(35 193 170 / var(--tw-bg-opacity));
}

.bg-\[\#25252D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 37 45 / var(--tw-bg-opacity));
}

.bg-\[\#282828\] {
  --tw-bg-opacity: 1;
  background-color: rgb(40 40 40 / var(--tw-bg-opacity));
}

.bg-\[\#282830\] {
  --tw-bg-opacity: 1;
  background-color: rgb(40 40 48 / var(--tw-bg-opacity));
}

.bg-\[\#2B2C32\] {
  --tw-bg-opacity: 1;
  background-color: rgb(43 44 50 / var(--tw-bg-opacity));
}

.bg-\[\#FBA43A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 164 58 / var(--tw-bg-opacity));
}

.bg-\[rgba\(62\2c 62\2c 69\2c 0\.85\)\] {
  background-color: rgba(62,62,69,0.85);
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/10 {
  background-color: rgb(0 0 0 / 0.1);
}

.bg-black\/20 {
  background-color: rgb(0 0 0 / 0.2);
}

.bg-black\/25 {
  background-color: rgb(0 0 0 / 0.25);
}

.bg-black\/30 {
  background-color: rgb(0 0 0 / 0.3);
}

.bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5);
}

.bg-black\/\[\.35\] {
  background-color: rgb(0 0 0 / .35);
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-jupiter-jungle-green {
  --tw-bg-opacity: 1;
  background-color: rgb(36 174 143 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-utility-error-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(85 22 12 / var(--tw-bg-opacity));
}

.bg-utility-warning-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(78 29 9 / var(--tw-bg-opacity));
}

.bg-v2-lily\/10 {
  background-color: rgb(232 249 255 / 0.1);
}

.bg-v2-lily\/5 {
  background-color: rgb(232 249 255 / 0.05);
}

.bg-v2-primary\/5 {
  background-color: rgba(199, 242, 132, 0.05);
}

.bg-v3-bg {
  background-color: rgba(28, 41, 54, 1);
}

.bg-v3-modal {
  --tw-bg-opacity: 1;
  background-color: rgb(34 43 51 / var(--tw-bg-opacity));
}

.bg-v3-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(199 242 132 / var(--tw-bg-opacity));
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(250 166 60 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1);
}

.bg-white\/5 {
  background-color: rgb(255 255 255 / 0.05);
}

.bg-white\/50 {
  background-color: rgb(255 255 255 / 0.5);
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.bg-opacity-\[15\%\] {
  --tw-bg-opacity: 15%;
}

.\!bg-none {
  background-image: none !important;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-\[\#EF2929\] {
  --tw-gradient-from: #EF2929 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 41 41 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-v3-primary {
  --tw-gradient-from: #c7f284 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(199 242 132 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#00BEF0\] {
  --tw-gradient-to: #00BEF0 var(--tw-gradient-to-position);
}

.to-\[\#FF7F7F\] {
  --tw-gradient-to: #FF7F7F var(--tw-gradient-to-position);
}

.bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text;
}

.fill-\[\#EF2929\] {
  fill: #EF2929;
}

.fill-current {
  fill: currentColor;
}

.fill-transparent {
  fill: transparent;
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.\!p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem;
}

.p-1\.5 {
  padding: 0.375rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[1px\] {
  padding: 1px;
}

.\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.\!text-xxs {
  font-size: 0.625rem !important;
  line-height: 1rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-xxs {
  font-size: 0.625rem;
  line-height: 1rem;
}

.font-\[500\] {
  font-weight: 500;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.\!leading-none {
  line-height: 1 !important;
}

.leading-\[1\.17\] {
  line-height: 1.17;
}

.leading-\[1\.67\] {
  line-height: 1.67;
}

.leading-none {
  line-height: 1;
}

.\!text-\[\#EF2929\] {
  --tw-text-opacity: 1 !important;
  color: rgb(239 41 41 / var(--tw-text-opacity)) !important;
}

.\!text-jupiter-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(251 164 58 / var(--tw-text-opacity)) !important;
}

.\!text-v3-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(199 242 132 / var(--tw-text-opacity)) !important;
}

.text-\[\#3171FF\] {
  --tw-text-opacity: 1;
  color: rgb(49 113 255 / var(--tw-text-opacity));
}

.text-\[\#333333\] {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-\[\#9D9DA6\] {
  --tw-text-opacity: 1;
  color: rgb(157 157 166 / var(--tw-text-opacity));
}

.text-\[\#AAAAAA\] {
  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
}

.text-\[\#EF2929\] {
  --tw-text-opacity: 1;
  color: rgb(239 41 41 / var(--tw-text-opacity));
}

.text-\[\#FCE94F\] {
  --tw-text-opacity: 1;
  color: rgb(252 233 79 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/50 {
  color: rgb(0 0 0 / 0.5);
}

.text-jupiter-primary {
  --tw-text-opacity: 1;
  color: rgb(251 164 58 / var(--tw-text-opacity));
}

.text-text-warning-primary {
  --tw-text-opacity: 1;
  color: rgb(253 176 34 / var(--tw-text-opacity));
}

.text-transparent {
  color: transparent;
}

.text-utility-error-700 {
  --tw-text-opacity: 1;
  color: rgb(253 162 155 / var(--tw-text-opacity));
}

.text-v2-lily {
  --tw-text-opacity: 1;
  color: rgb(232 249 255 / var(--tw-text-opacity));
}

.text-v2-lily\/50 {
  color: rgb(232 249 255 / 0.5);
}

.text-v2-primary {
  color: rgba(199, 242, 132, 1);
}

.text-v3-primary {
  --tw-text-opacity: 1;
  color: rgb(199 242 132 / var(--tw-text-opacity));
}

.text-v3-primary\/50 {
  color: rgb(199 242 132 / 0.5);
}

.text-warning {
  --tw-text-opacity: 1;
  color: rgb(250 166 60 / var(--tw-text-opacity));
}

.text-warning\/50 {
  color: rgb(250 166 60 / 0.5);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/20 {
  color: rgb(255 255 255 / 0.2);
}

.text-white\/30 {
  color: rgb(255 255 255 / 0.3);
}

.text-white\/40 {
  color: rgb(255 255 255 / 0.4);
}

.text-white\/50 {
  color: rgb(255 255 255 / 0.5);
}

.text-white\/70 {
  color: rgb(255 255 255 / 0.7);
}

.text-white\/75 {
  color: rgb(255 255 255 / 0.75);
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-white\/5 {
  --tw-ring-color: rgb(255 255 255 / 0.05);
}

.ring-white\/50 {
  --tw-ring-color: rgb(255 255 255 / 0.5);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[20px\] {
  --tw-backdrop-blur: blur(20px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#jupiter-terminal *:focus {
  outline: none;
}

/* JupButton border gradient */

#jupiter-terminal .jup-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.5rem;
  padding: 2px;
  background: linear-gradient(91.26deg, #fcc00a 15.73%, #4ebae9 83.27%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: source-out;
}

.hideScrollbar {
  -ms-overflow-style: none;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.hideScrollbar::-moz-scrollbar {
  display: none;
}

/* Custom scroll bar */

.webkit-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.webkit-scrollbar::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: #979797;
}

#jupiter-terminal .jup-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.5rem;
  padding: 2px;
  background: linear-gradient(91.26deg, #fcc00a 15.73%, #4ebae9 83.27%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: source-out;
}

/* Add tooltip functionality */

.jupiter-tooltip {
  visibility: hidden;
  position: absolute;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  padding: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.jupiter-has-tooltip:hover .jupiter-tooltip {
  visibility: visible;
  z-index: 50;
}

.Toastify__toast-theme--dark {
  background: black !important;
  border-radius: 2px !important;
}

.Toastify__toast-theme--light {
  background: #fff !important;
  border-radius: 12px !important;
}

.Toastify__toast--success {
  border: 1px solid #28cd41 !important;
}

.Toastify__toast--error {
  border: 1px solid #ef2929 !important;
}

.Toastify__toast--warning {
  border: 1px solid #ff9500 !important;
}

.Toastify__toast--info {
  border: 1px solid #0065d1 !important;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__close-button {
  display: none !important;
}

.Toastify__progress-bar {
  display: none;
}

.Toastify__progress-bar--bg {
  display: none;
}

.cursor-pointer {
  cursor: url('../../public/icons/cursor-pointer.svg'), pointer;
}

.cursor-default {
  cursor: url('../../public/icons/cursor-default.svg'), default;
}

.placeholder\:text-\[16px\]::-moz-placeholder {
  font-size: 16px;
}

.placeholder\:text-\[16px\]::placeholder {
  font-size: 16px;
}

.placeholder\:text-v2-lily\/10::-moz-placeholder {
  color: rgb(232 249 255 / 0.1);
}

.placeholder\:text-v2-lily\/10::placeholder {
  color: rgb(232 249 255 / 0.1);
}

.placeholder\:text-v2-lily\/25::-moz-placeholder {
  color: rgb(232 249 255 / 0.25);
}

.placeholder\:text-v2-lily\/25::placeholder {
  color: rgb(232 249 255 / 0.25);
}

.placeholder\:text-white\/25::-moz-placeholder {
  color: rgb(255 255 255 / 0.25);
}

.placeholder\:text-white\/25::placeholder {
  color: rgb(255 255 255 / 0.25);
}

.before\:visible::before {
  content: var(--tw-content);
  visibility: visible;
}

.before\:absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.before\:h-2::before {
  content: var(--tw-content);
  height: 0.5rem;
}

.before\:w-2::before {
  content: var(--tw-content);
  width: 0.5rem;
}

.before\:rotate-45::before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:bg-inherit::before {
  content: var(--tw-content);
  background-color: inherit;
}

.last\:border-b:last-child {
  border-bottom-width: 1px;
}

.last\:border-white\/10:last-child {
  border-color: rgb(255 255 255 / 0.1);
}

.focus-within\:border-\[\#FF7F7F\]:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(255 127 127 / var(--tw-border-opacity));
}

.focus-within\:shadow-swap-input-dark:focus-within {
  --tw-shadow: 0px 2px 16px rgba(199, 242, 132, 0.25);
  --tw-shadow-colored: 0px 2px 16px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:border-\[\#FF7F7F\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 127 127 / var(--tw-border-opacity));
}

.hover\:bg-black\/20:hover {
  background-color: rgb(0 0 0 / 0.2);
}

.hover\:bg-white\/10:hover {
  background-color: rgb(255 255 255 / 0.1);
}

.hover\:bg-white\/20:hover {
  background-color: rgb(255 255 255 / 0.2);
}

.hover\:text-\[\#FCE94F\]:hover {
  --tw-text-opacity: 1;
  color: rgb(252 233 79 / var(--tw-text-opacity));
}

.hover\:text-\[\#FF7F7F\]:hover {
  --tw-text-opacity: 1;
  color: rgb(255 127 127 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-70:hover {
  opacity: 0.7;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:text-\[\#2B2929\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(43 41 41 / var(--tw-text-opacity));
}

.disabled\:opacity-50:disabled {
  opacity: 0.5;
}

.disabled\:hover\:bg-\[\#36373E\]:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(54 55 62 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group:hover .group-hover\:z-50 {
  z-index: 50;
}

.group:hover .group-hover\:fill-\[\#FF7F7F\] {
  fill: #FF7F7F;
}

.group:hover .group-hover\:text-\[\#C4A000\] {
  --tw-text-opacity: 1;
  color: rgb(196 160 0 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-\[\#FCE94F\] {
  --tw-text-opacity: 1;
  color: rgb(252 233 79 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-\[\#FF7F7F\] {
  --tw-text-opacity: 1;
  color: rgb(255 127 127 / var(--tw-text-opacity));
}

.group:disabled:hover .group-disabled\:group-hover\:fill-\[\#EF2929\] {
  fill: #EF2929;
}

.group:disabled:hover .group-disabled\:group-hover\:text-\[\#EF2929\] {
  --tw-text-opacity: 1;
  color: rgb(239 41 41 / var(--tw-text-opacity));
}

:is(.dark .dark\:border-white\/25) {
  border-color: rgb(255 255 255 / 0.25);
}

:is(.dark .dark\:bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-white\/5) {
  background-color: rgb(255 255 255 / 0.05);
}

:is(.dark .dark\:text-black) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white\/50) {
  color: rgb(255 255 255 / 0.5);
}

:is(.dark .dark\:hover\:text-v2-primary:hover) {
  color: rgba(199, 242, 132, 1);
}

@media (min-width: 768px) {
  .md\:right-2 {
    right: 0.5rem;
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mt-14 {
    margin-top: 3.5rem;
  }

  .md\:\!flex {
    display: flex !important;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[34px\] {
    height: 34px;
  }

  .md\:max-h-\[600px\] {
    max-height: 600px;
  }

  .md\:w-\[384px\] {
    width: 384px;
  }

  .md\:max-w-\[340px\] {
    max-width: 340px;
  }

  .md\:max-w-\[60\%\] {
    max-width: 60%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:border-none {
    border-style: none;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:text-\[52px\] {
    font-size: 52px;
  }
}

@media (min-width: 1024px) {
  .lg\:\!top-\[12\.5\%\] {
    top: 12.5% !important;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:min-h-\[600px\] {
    min-height: 600px;
  }

  .lg\:w-\[75\%\] {
    width: 75%;
  }

  .lg\:max-w-\[80\%\] {
    max-width: 80%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-\[70\%\] {
    max-width: 70%;
  }
}
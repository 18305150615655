@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-white-100: hsl(0, 0%, 100%);
  --color-white-200: hsl(0, 0%, 95%);
  --color-white-300: hsl(0, 0%, 85%);
  --color-white-400: hsl(0, 0%, 75%);
  --color-white-500: hsl(0, 0%, 65%);
  --color-white-600: hsl(0, 0%, 55%);

  --color-black-100: hsl(210, 17%, 12%);
  --color-black-200: hsl(210, 17%, 10%);
  --color-black-300: hsl(210, 16%, 8%);
  --color-black-400: hsl(210, 16%, 6%);
  --color-black-500: hsl(210, 15%, 4%);
  --color-black-600: hsl(210, 15%, 2%);

  --text-tiny: clamp(0.84rem, calc(0.12vw + 0.8rem), 0.89rem);
  --text-small: clamp(0.93rem, calc(0.13vw + 0.91rem), 1rem);
  --text-base: clamp(1.05rem, calc(0.15vw + 1.01rem), 1.12rem);
  --text-medium: clamp(1.18rem, calc(0.17vw + 1.14rem), 1.26rem);
  --text-large: clamp(1.32rem, calc(0.19vw + 1.28rem), 1.41rem);

  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

html {
  scroll-behavior: smooth;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.Toastify__toast-theme--dark {
  background: black !important;
  border-radius: 2px !important;
}
.Toastify__toast-theme--light {
  background: #fff !important;
  border-radius: 12px !important;
}

.Toastify__toast--success {
  border: 1px solid #28cd41 !important;
}

.Toastify__toast--error {
  border: 1px solid #ef2929 !important;
}

.Toastify__toast--warning {
  border: 1px solid #ff9500 !important;
}

.Toastify__toast--info {
  border: 1px solid #0065d1 !important;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__close-button {
  display: none !important;
}

.Toastify__progress-bar {
  display: none;
}

.Toastify__progress-bar--bg {
  display: none;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  background-color: black !important;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(5px, 5px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes vertical-shaking {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
    background: "red";
  }
  25% {
    transform: translateX(-60px);
  }
  50% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes jump-shaking {
  0% {
    transform: translateX(0);
    background: "red";
  }
  25% {
    transform: translateY(-9px);
  }
  35% {
    transform: translateY(-9px) rotate(17deg);
  }
  55% {
    transform: translateY(-9px) rotate(-17deg);
  }
  65% {
    transform: translateY(-9px) rotate(17deg);
  }
  75% {
    transform: translateY(-9px) rotate(-17deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes jump-shaking-diamond {
  0% {
    transform: rotate(17deg);
  }
  25% {
    transform: rotate(-17deg);
  }
  50% {
    transform: rotate(17deg);
  }
  75% {
    transform: rotate(-17deg);
  }
  100% {
    transform: rotate(17deg);
  }
}

@keyframes skew-x-shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}

@keyframes skew-y-shake {
  0% {
    transform: skewY(-15deg);
  }
  5% {
    transform: skewY(15deg);
  }
  10% {
    transform: skewY(-15deg);
  }
  15% {
    transform: skewY(15deg);
  }
  20% {
    transform: skewY(0deg);
  }
  100% {
    transform: skewY(0deg);
  }
}

@keyframes mymove {
  0% {
    background-color: #2b2929;
  }
  50% {
    background-color: #ff7f7f;
  }
  100% {
    background-color: #2b2929;
  }
}

@keyframes blink {
  0% {
    border-color: var(--chakra-colors-primary-yellow);
    color: var(--chakra-colors-primary-yellow);
  }
  50% {
    border-color: var(--chakra-colors-primary-red-hover);
    color: var(--chakra-colors-primary-red-hover);
  }
  100% {
    border-color: var(--chakra-colors-primary-yellow);
    color: var(--chakra-colors-primary-yellow);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes spin_rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

[class*="-ModalDialog-ModalDialog"] {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px !important;
}

.css-1vx73dy {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px !important;
}

@media screen and (min-width: 768px) {
  [class*="-ModalDialog-ModalDialog"] {
    margin: 0;
    padding: 0;
    font-size: 20px !important;
  }

  .css-1vx73dy {
    margin: 0;
    padding: 0;
    font-size: 20px !important;
  }
}

[class*="-ModalDialog-ModalDialog"]
  > [class*="-UnifiedWalletModal-UnifiedWalletModal"],
.css-1vx73dy > .css-1m96a42,
.css-1m96a42 {
  font-size: 20px !important;
  border-radius: 1px !important;
  background-color: #000 !important;
}

[class*="-UnifiedWalletModal-UnifiedWalletModal"] {
  max-width: 500px !important;
}

.css-1m96a42 {
  max-width: 500px !important;
}

.css-1vx73dy > .css-1m96a42 {
  font-size: 20px !important;
  border-radius: 1px !important;
  background-color: #000 !important;
}

.css-1m96a42 {
  font-size: 20px !important;
  border-radius: 1px !important;
  background-color: #000 !important;
}

[class*="-WalletListItem"] {
  font-size: 14px !important;
}
.css-18g8lgp {
  font-size: 14px !important;
}
